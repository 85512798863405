import axios from 'axios';

import history from './history';

import Swal from 'sweetalert2';

import { errorPath, loginAppPath, refreshEmailPath } from './paths';
import { isThompsons } from './helpers';

const setInterceptors = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        !isThompsons() &&
        error.response.status !== 422 &&
        error.response.status !== 403 &&
        !(
          error.response.status === 401 &&
          window.location.pathname === loginAppPath
        )
      ) {
        Swal.fire({
          position: 'bottom-start',
          icon: 'error',
          title: 'Ooops something went wrong',
          text: error.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (error.response.status === 500) {
        history.push(errorPath);
      }

      if (
        error.response.status === 403 &&
        !(window.location.pathname === refreshEmailPath)
      ) {
        history.push(loginAppPath);
        window.location.reload();
      }

      return Promise.reject(error);
    }
  );
};

export default setInterceptors;
