/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  SET_CLAIM_PRELOAD,
  CLEAR_CLAIM_PRELOAD,
  CLEAR_CLAIM_TASKS,
} from 'actions/types';

const initialState = { list: [], errorText: '', preloadedClaim: null };

const claimTaskRestTypes = restTypes('claimTask');

const { fetchAllStart, fetchAllFinished } = claimTaskRestTypes;

const ClaimTask = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case fetchAllFinished:
      if (
        action.payload &&
        action.payload.tasks &&
        Array.isArray(action.payload.tasks)
      ) {
        return {
          ...state,
          ...restStates.fetchAllFinished,
          list: action.payload.tasks,
          pageCurrent: action.payload.meta && action.payload.meta.currentPage,
          pagesTotal: action.payload.meta && action.payload.meta.totalPages,
        };
      }
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: [],
        pageCurrent: 0,
        pagesTotal: 0,
      };

    case SET_CLAIM_PRELOAD:
      return {
        ...state,
        preloadedClaim: action.payload,
      };

    case CLEAR_CLAIM_PRELOAD:
      return {
        ...initialState,
      };

    case CLEAR_CLAIM_TASKS:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};

export default ClaimTask;
