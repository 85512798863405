const states = {
  fetchOneStart: {
    fetchingOne: true,
    fetchedOne: false,
  },
  fetchOneFinished: {
    fetchingOne: false,
    fetchedOne: true,
  },
  fetchAllStart: {
    fetching: true,
    fetched: false,
  },
  fetchAllFinished: {
    fetching: false,
    fetched: true,
  },
  updateStart: {
    updating: true,
    updated: false,
  },
  updateFinished: {
    updating: false,
    updated: true,
  },
  deleteStart: {
    deleting: true,
    deleted: false,
  },
  deleteFinished: {
    deleting: false,
    deleted: true,
  },
  createStart: {
    creating: true,
    created: false,
  },
  createFinished: {
    creating: false,
    created: true,
  },
  fetchOrganisationClaimsStart: {
    fetchingOrganisationClaims: true,
    organisationClaimsFetched: false,
  },
  fetchOrganisationClaimsFinished: {
    fetchingOrganisationClaims: false,
    organisationClaimsFetched: true,
  },
  fetchPeopleClaimsStart: {
    fetchingPeopleClaims: true,
    PeopleClaimsFetched: false,
  },
  fetchPeopleClaimsFinished: {
    fetchingPeopleClaims: false,
    PeopleClaimsFetched: true,
  },
};

export default states;
