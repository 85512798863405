import axios from 'axios';

import config from './config';

const setCompanyAlias = () => {
  let alias = '';
  const env = config.env;

  if (env === 'development') {
    alias = window.location.hostname.split('.')[0];
  } else {
    alias = window.location.hostname.split('.')[0];
  }

  axios.defaults.headers.common['Company-Domain'] = window.location.hostname;
  // axios.defaults.headers.common['Company-Domain'] = alias;
  axios.defaults.headers.common['X-APP-TYPE'] = 'cms';
  axios.defaults.headers.common['Referrer-Policy'] = 'origin';
};

export default setCompanyAlias;
