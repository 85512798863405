/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

const initialState = { errorText: '' };

const settingsRestTypes = restTypes('settings');

const { fetchOneStart, fetchOneFinished } = settingsRestTypes;

const settings = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default settings;
