import restTypes from "./types";

export default class RestActions {
  constructor(resource) {
    this.resourceRestTypes = restTypes(resource);
  }

  fetchStart = payload => ({
    type: this.resourceRestTypes.fetchOneStart,
    payload
  });

  fetchFinished = payload => ({
    type: this.resourceRestTypes.fetchOneFinished,
    payload
  });

  fetchAllStart = payload => ({
    type: this.resourceRestTypes.fetchAllStart,
    payload
  });

  fetchAllFinished = payload => ({
    type: this.resourceRestTypes.fetchAllFinished,
    payload
  });

  updateStart = payload => ({
    type: this.resourceRestTypes.updateStart,
    payload
  });

  updateFinished = payload => ({
    type: this.resourceRestTypes.updateFinished,
    payload
  });

  deleteStart = payload => ({
    type: this.resourceRestTypes.deleteStart,
    payload
  });

  deleteFinished = payload => ({
    type: this.resourceRestTypes.deleteFinished,
    payload
  });

  createStart = payload => ({
    type: this.resourceRestTypes.createStart,
    payload
  });

  createFinished = payload => ({
    type: this.resourceRestTypes.createFinished,
    payload
  });

  fetchOrganisationClaimsStart = payload => ({
    type: this.resourceRestTypes.fetchOrganisationClaimsStart,
    payload
  });

  fetchOrganisationClaimsFinished = payload => ({
    type: this.resourceRestTypes.fetchOrganisationClaimsFinished,
    payload
  });

  fetchPeopleClaimsStart = payload => ({
    type: this.resourceRestTypes.fetchOrganisationClaimsStart,
    payload
  });

  fetchPeopleClaimsFinished = payload => ({
    type: this.resourceRestTypes.fetchOrganisationClaimsFinished,
    payload
  });
}
