import { SET_MOBILE_HEADER } from 'actions/types';

const initialState = {
  isOpen: false,
};

const header = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case SET_MOBILE_HEADER:
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
};

export default header;
