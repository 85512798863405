import React from 'react';

import { errorPath } from 'utils/paths';
import { withRouter } from 'hooks/withRouter';

import Settings from './Settings';

import Window from './Window';
import MainRouter from '../routes/MainRouter';

import 'assets/scss/index.scss';

class App extends React.Component {
  componentDidCatch() {
    // this.props.history.push(errorPath);
    this.props.navigate(errorPath);
  }
  static getDerivedStateFromError() {
    // this.props.history.push(errorPath);
    this.props.navigate(errorPath);
  }

  render() {
    return (
      // Window component adds event listeners
      <Window>
        {/* Setting ensures that settings has been fetched before the displaying all components */}
        <Settings>
          <MainRouter />
        </Settings>
      </Window>
    );
  }
}

export default withRouter(App);
