import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './styles.scss';

const Button = ({
  className,
  type,
  backgroundColor,
  onClick,
  size,
  value,
  name,
  disabled,
  icon,
  pathTo,
  contentReverse,
  presentation,
  targetBlank,
  isIconSuffix,
}) => {
  const buttonType = type ? `btn-${type}` : '';
  const buttonSize = size ? `btn-${size}` : '';
  const buttonIcon =
    icon && value ? `btn-icon ${!isIconSuffix ? 'right' : 'left'}` : '';
  const buttonIconSingle = icon && !value ? 'btn-icon-single' : '';
  const reverseClass = contentReverse ? 'btn-content reverse' : 'btn-content';
  return !presentation ? (
    <Fragment>
      {!pathTo ? (
        <button
          style={{ backgroundColor }}
          className={`btn ${buttonType} ${buttonSize} ${className} ${buttonIcon} ${buttonIconSingle}`}
          onClick={onClick}
          name={name}
          disabled={disabled}
          value={value}
        >
          <div className="btn-content">
            {!isIconSuffix ? icon : null}
            <span className="btn-text">{value}</span>
            {isIconSuffix ? icon : null}
          </div>
        </button>
      ) : (
        <Fragment>
          {targetBlank ? (
            <a
              href={pathTo}
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor }}
              className={`btn btn-link ${buttonType} ${buttonSize} ${className} ${buttonIcon} ${buttonIconSingle}`}
            >
              <div className={reverseClass}>
                {icon}
                <span className="btn-text">{value}</span>
              </div>
            </a>
          ) : (
            <Link
              to={pathTo}
              onClick={onClick}
              style={{ backgroundColor }}
              className={`btn btn-link ${buttonType} ${buttonSize} ${className} ${buttonIcon} ${buttonIconSingle}`}
            >
              <div style={{ backgroundColor }} className={reverseClass}>
                {icon}
                <span className="btn-text">{value}</span>
              </div>
            </Link>
          )}
        </Fragment>
      )}
    </Fragment>
  ) : (
    <div
      style={{ backgroundColor }}
      className={`btn ${buttonType} ${buttonSize} ${className} ${buttonIcon} ${buttonIconSingle}`}
      disabled={disabled}
    >
      <div className="btn-content clickable">
        {icon}
        <span className="btn-text">{value}</span>
      </div>
    </div>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  pathTo: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  contentReverse: PropTypes.bool,
  presentation: PropTypes.bool,
  icon: PropTypes.node,
  targetBlank: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  name: '',
  type: '',
  backgroundColor: '',
  size: '',
  onClick: null,
  value: null,
  disabled: false,
  icon: null,
  pathTo: '',
  // pathTo: rootAppPath,
  contentReverse: false,
  presentation: false,
  targetBlank: false,
};

export default Button;
