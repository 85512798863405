/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  SET_PEOPLE_FIELD,
  SET_PEOPLE_FIELDS,
  SET_PEOPLE_EDIT_MODE,
  CLEAR_PEOPLE_DATA,
} from 'actions/types';

const initialState = {
  list: [],
  fields: {},
  changedFields: {},
  fieldsUpdated: false,
  editMode: false,
  claims: [],
  documents: [],
  lastUploadedDocument: {},
  hasErrors: false,
  errors: {},
};

const peopleRestTypes = restTypes('people');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchOneStart,
  fetchOneFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
  fetchPeopleClaimsStart,
  fetchPeopleClaimsFinished,
} = peopleRestTypes;

const people = (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };

    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload.persons,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };

    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
        hasErrors: false,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
        hasErrors: false,
        errors: {},
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        hasErrors: action.payload.hasErrors,
        errors: action.payload.errors,
      };

    case fetchPeopleClaimsStart:
      return {
        ...state,
        ...restStates.fetchPeopleClaimsStart,
      };

    case fetchPeopleClaimsFinished:
      return {
        ...state,
        ...restStates.fetchPeopleClaimsFinished,
        claims: action.payload.result,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case SET_PEOPLE_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        changedFields: {
          ...state.changedFields,
          [action.payload.name]: action.payload.value,
        },
      };

    case SET_PEOPLE_FIELDS:
      return {
        ...state,
        fields: action.payload,
        fieldsUpdated: true,
      };

    case SET_PEOPLE_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload,
      };

    case CLEAR_PEOPLE_DATA:
      return {
        ...state,
        data: {},
        fields: {},
        claims: [],
      };

    default:
      return state;
  }
};

export default people;
