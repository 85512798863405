/* TYPES */
import {
  SET_IS_AUTHORIZED,
  SEND_RECOVERY_EMAIL_START,
  SEND_RECOVERY_EMAIL_FINISH,
} from 'actions/types';

/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

const initialState = {
  errorText: '',
};

const authRestTypes = restTypes('auth');

const { fetchOneStart, fetchOneFinished, createStart, createFinished } =
  authRestTypes;

const auth = (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        errorText: action.payload.errorText,
      };

    case SET_IS_AUTHORIZED:
      return {
        ...state,
        isAuthorized: action.payload,
      };

    case SEND_RECOVERY_EMAIL_START:
      return {
        ...state,
        sendStarted: true,
        sendFinished: false,
      };

    case SEND_RECOVERY_EMAIL_FINISH:
      return {
        ...state,
        sendStarted: false,
        sendFinished: true,
        hasErrors: action.payload.hasErrors,
        errorText: action.payload.errorText,
      };

    default:
      return state;
  }
};

export default auth;
