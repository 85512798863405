import axios from 'axios';
import { unlockAccountPath } from 'utils/paths';
/* TYPES */
import {
  SET_ACCOUNT_FIELD,
  SET_ACCOUNT_FIELDS,
  CLEAR_ACCOUNT_STATE,
  UNLOCK_ACCOUNT_STARTED,
  UNLOCK_ACCOUNT_FINISHED
} from './types';

/* ACTIONS */
export const setAccField = (name, value) => dispatch => {
  dispatch({
    type: SET_ACCOUNT_FIELD,
    payload: { name, value }
  });
};

export const setAccFields = fields => dispatch => {
  dispatch({
    type: SET_ACCOUNT_FIELDS,
    payload: fields
  });
};

export const unlockAccount = unlock_token => async (dispatch) => {
  dispatch({ type: UNLOCK_ACCOUNT_STARTED });
  await axios.post(unlockAccountPath, {
    unlock_token
  })
  dispatch({ type: UNLOCK_ACCOUNT_FINISHED });
};

export const clearAccountState = cb => dispatch => {
  dispatch({
    type: CLEAR_ACCOUNT_STATE
  });

  if (cb) cb();
};
