/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

import { CLEAR_SUGGESTIONS } from 'actions/types';

const initialState = {
  suggestions: {},
};

const autocompleteRestTypes = restTypes('autocomplete');

const { fetchAllStart, fetchAllFinished } = autocompleteRestTypes;

const autocomplete = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };

    case fetchAllFinished:
      let selectedUser = {};
      if (
        action.payload.result &&
        Array.isArray(action.payload.result) &&
        action.payload.result.length > 0
      ) {
        selectedUser = action.payload.result[0];
      }
      return {
        ...state,
        ...restStates.fetchAllFinished,
        suggestions: {
          ...state.suggestions,
          [action.payload.key]: action.payload.result,
        },
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
        customer:
          action.payload.result &&
          action.payload.result[0] &&
          action.payload.result[0].customer,
        selectedUser,
      };

    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: {},
        // customer: null
      };

    default:
      return state;
  }
};

export default autocomplete;
