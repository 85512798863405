// BROWSER
export const SET_WINDOW_RESIZE = 'SET_WINDOW_RESIZE';
export const SET_WINDOW_RESIZE_FINISHED = 'SET_WINDOW_RESIZE_FINISHED';
export const SET_WINDOW_ORIENTATION = 'SET_WINDOW_ORIENTATION';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_ONLINE = 'SET_ONLINE';
export const SET_SCROLL = 'SET_SCROLL';

// HEADER
export const SET_MOBILE_HEADER = 'SET_MOBILE_HEADER';

export const SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED';

// AUTH
export const SEND_RECOVERY_EMAIL_START = 'SEND_RECOVERY_EMAIL_START';
export const SEND_RECOVERY_EMAIL_FINISH = 'SEND_RECOVERY_EMAIL_FINISH';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';

// ACCOUNT
export const SET_ACCOUNT_FIELD = 'SET_ACCOUNT_FIELD';
export const SET_ACCOUNT_FIELDS = 'SET_ACCOUNT_FIELDS';
export const CLEAR_ACCOUNT_STATE = 'CLEAR_ACCOUNT_STATE';
export const UNLOCK_ACCOUNT_STARTED = 'UNLOCK_ACCOUNT_STARTED';
export const UNLOCK_ACCOUNT_FINISHED = 'UNLOCK_ACCOUNT_FINISHED';

// CUSTOMER
export const SET_CUSTOMER_FIELD = 'SET_CUSTOMER_FIELD';
export const SET_CUSTOMER_FIELDS = 'SET_CUSTOMER_FIELDS';
export const SET_CUSTOMER_EDIT_MODE = 'SET_CUSTOMER_EDIT_MODE';
export const FETCH_AGENTS_START = 'FETCH_AGENTS_START';
export const FETCH_AGENTS_FINISHED = 'FETCH_AGENTS_FINISHED';
export const CLEAR_CUSTOMER_DATA = 'CLEAR_CUSTOMER_DATA';

// PEOPLE
export const SET_PEOPLE_FIELD = 'SET_PEOPLE_FIELD';
export const SET_PEOPLE_FIELDS = 'SET_PEOPLE_FIELDS';
export const SET_PEOPLE_EDIT_MODE = 'SET_PEOPLE_EDIT_MODE';
export const CLEAR_PEOPLE_DATA = 'CLEAR_PEOPLE_DATA';

// ORGANIZATION
export const SET_ORGANIZATION_FIELD = 'SET_ORGANIZATION_FIELD';
export const SET_ORGANIZATION_FIELDS = 'SET_ORGANIZATION_FIELDS';
export const SET_ORGANIZATION_EDIT_MODE = 'SET_ORGANIZATION_EDIT_MODE';
export const CLEAR_ORGANIZATION_DATA = 'CLEAR_ORGANIZATION_DATA';

// CUSTOMER CLAIM
export const SET_CUSTOMER_PRELOAD = 'SET_CUSTOMER_PRELOAD';
export const CLEAR_CUSTOMER_PRELOAD = 'CLEAR_CUSTOMER_PRELOAD';

// TASK
export const SET_TASK_GROUPS = 'SET_TASK_GROUPS';
export const COMPLETE_TASK_START = 'COMPLETE_TASK_START';
export const COMPLETE_TASK_FINISH = 'COMPLETE_TASK_FINISH';
export const FETCH_FORM_TYPES_START = 'FETCH_FORM_TYPES_START';
export const FETCH_FORM_TYPES_FINISH = 'FETCH_FORM_TYPES_FINISH';
export const SET_FORM_ANSWER = 'SET_FORM_ANSWER';
export const SET_FORM_CLAIM_ANSWER = 'SET_FORM_CLAIM_ANSWER';
export const CLEAR_TASK_ANSWERS = 'CLEAR_TASK_ANSWERS';
export const CLEAR_TASK_DATA = 'CLEAR_TASK_DATA';

// CLAIM
export const SET_CLAIM_GROUPS = 'SET_CLAIM_GROUPS';
export const SET_CLAIM_ANSWER = 'SET_CLAIM_ANSWER';
export const FETCH_CLAIM_TYPES_START = 'FETCH_CLAIM_TYPES_START';
export const FETCH_CLAIM_TYPES_FINISH = 'FETCH_CLAIM_TYPES_FINISH';
export const CLEAR_CLAIM_ANSWERS = 'CLEAR_CLAIM_ANSWERS';
export const DELETE_EMPTY_ANSWERS = 'DELETE_EMPTY_ANSWERS';
export const CLEAR_CLAIM_DATA = 'CLEAR_CLAIM_DATA';
export const CLEAR_CLAIM_GROUP = 'CLEAR_CLAIM_GROUP';
export const LINK_CLAIMS_START = 'LINK_CLAIMS_START';
export const LINK_CLAIMS_FINISHED = 'LINK_CLAIMS_FINISHED';
export const FETCH_LINKED_CLAIMS_START = 'FETCH_LINKED_CLAIMS_START';
export const FETCH_LINKED_CLAIMS_FINISHED = 'FETCH_LINKED_CLAIMS_FINISHED';
export const UPDATE_LINKED_CLAIMS_START = 'UPDATE_LINKED_CLAIMS_START';
export const UPDATE_LINKED_CLAIMS_FINISHED = 'UPDATE_LINKED_CLAIMS_FINISHED';
export const FETCH_LINKED_CLAIMS_SEARCH_START =
  'FETCH_LINKED_CLAIMS_SEARCH_START';
export const FETCH_LINKED_CLAIMS_SEARCH_FINISHED =
  'FETCH_LINKED_CLAIMS_SEARCH_FINISHED';

// CLAIM DOCUMENTS
export const CLEAR_DOCUMENT_LIST = 'CLEAR_DOCUMENT_LIST';
export const UPLOADED_FILE = 'UPLOADED_FILE';
export const SET_FILE_SOURCE = 'SET_FILE_SOURCE';
export const CLEAR_MESSAGE_POPUP = 'CLEAR_MESSAGE_POPUP';
export const REMOVE_ATTACHEMENT_FILE = 'REMOVE_ATTACHEMENT_FILE';
export const CLEAR_DOCUMENT_ERRORS = 'CLEAR_DOCUMENT_ERRORS';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE';
export const DISABLE_UPLOAD = 'DISABLE_UPLOAD';
export const ENABLE_UPLOAD = 'ENABLE_UPLOAD';
export const SHOW_PROGRESS = 'SHOW_PROGRESS';
export const HIDE_PROGRESS = 'HIDE_PROGRESS';

// CLAIM TASKS
export const CLEAR_CLAIM_TASKS = 'CLEAR_CLAIM_TASKS';

// TASK CLAIM
export const SET_TASK_CLAIM_GROUPS = 'SET_TASK_CLAIM_GROUPS';
export const SET_CLAIM_PRELOAD = 'SET_CLAIM_PRELOAD';
export const CLEAR_CLAIM_PRELOAD = 'CLEAR_CLAIM_PRELOAD';
export const CLEAR_TASK_CLAIM_ANSWERS = 'CLEAR_TASK_CLAIM_ANSWERS';

// DOCUMENTS
export const SET_DOCUMENT_PREVIEW = 'SET_DOCUMENT_PREVIEW';
export const REMOVE_DOCUMENT_PREVIEW = 'REMOVE_DOCUMENT_PREVIEW';
export const REMOVE_MULTIPLE_DOCUMENT_PREVIEW =
  'REMOVE_MULTIPLE_DOCUMENT_PREVIEW';
export const CLEAR_PREVIEWS_LIST = 'CLEAR_PREVIEWS_LIST';
export const HIDE_MULTIPLE_PREVIEW = 'HIDE_MULTIPLE_PREVIEW';
export const HIDE_SINGLE_PREVIEW = 'HIDE_SINGLE_PREVIEW';
export const SET_VIDEO_PREVIEW = 'SET_VIDEO_PREVIEW';
export const REMOVE_VIDEO_PREVIEW = 'REMOVE_VIDEO_PREVIEW';

// SUGGESTIONS
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';
export const SET_SUGGESTION = 'SET_SUGGESTION';
export const CLEAR_CONTACT_SUGGESTIONS = 'CLEAR_CONTACT_SUGGESTIONS';
export const SET_SUGGESTION_PEOPLE_ORGANIZATIONS =
  'SET_SUGGESTION_PEOPLE_ORGANIZATIONS';
export const SET_SUGGESTION_PERSON_OR_ORGANIZATION =
  'SET_SUGGESTION_PERSON_OR_ORGANIZATION';

//ROLES
export const FETCH_ROLES = 'FETCH_ROLES';

//CORRESPONDENCE
export const CLEAR_CORRESPONDENCE_LIST = 'CLEAR_CORRESPONDENCE_LIST';
export const FETCH_CORRESPONDENCE_EVENTS = 'FETCH_CORRESPONDENCE_EVENTS';
export const CLEAR_CORRESPONDENCE_EVENTS = 'CLEAR_CORRESPONDENCE_EVENTS';

// SESSION
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
