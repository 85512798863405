import _ from 'lodash';
/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  CLEAR_DOCUMENT_LIST,
  UPLOADED_FILE,
  SET_FILE_SOURCE,
  CLEAR_MESSAGE_POPUP,
  REMOVE_ATTACHEMENT_FILE,
  CLEAR_DOCUMENT_ERRORS,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  DISABLE_UPLOAD,
  ENABLE_UPLOAD,
  SHOW_PROGRESS,
  HIDE_PROGRESS,
} from 'actions/types';

const initialState = {
  list: [],
  disableUpload: false,
  showProgress: false,
};

const claimDocumentRestTypes = restTypes('claimDocument');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchOneStart,
  fetchOneFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
} = claimDocumentRestTypes;

const claimDocument = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };

    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload.documents,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };

    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };

    case createFinished:
      const response = _.get(action.payload, 'response', null);
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        fileUploadError:
          response &&
          response.status &&
          Number.parseInt(response.status) === 422
            ? response.data.file
            : [],
      };

    case CLEAR_DOCUMENT_LIST:
      return {
        ...initialState,
      };

    case UPLOADED_FILE:
      const { formData } = action.payload;
      return {
        ...state,
        formData,
      };

    case SET_FILE_SOURCE:
      return {
        ...state,
        fileSource: action.payload,
      };

    case CLEAR_MESSAGE_POPUP:
      return {
        ...state,
        formData: {},
      };

    case REMOVE_ATTACHEMENT_FILE:
      return {
        ...state,
        formData: {
          ...state.formData,
          attachments: state.formData.attachments.filter(
            (attach) => attach.id !== action.payload
          ),
        },
      };

    case CLEAR_DOCUMENT_ERRORS:
      return {
        ...state,
        fileUploadError: [],
      };

    case SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        showSuccessMsg: true,
      };

    case HIDE_SUCCESS_MESSAGE:
      return {
        ...state,
        showSuccessMsg: false,
      };

    case DISABLE_UPLOAD:
      return {
        ...state,
        disableUpload: true,
      };

    case ENABLE_UPLOAD:
      return {
        ...state,
        disableUpload: false,
      };

    case SHOW_PROGRESS:
      return {
        ...state,
        showProgress: true,
      };

    case HIDE_PROGRESS:
      return {
        ...state,
        showProgress: false,
      };

    default:
      return state;
  }
};

export default claimDocument;
