import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import {
  errorPath,
  loginAppPath,
  recoveryPath,
  refreshEmailPath,
  rootAppPath,
  setPasswordPath,
} from 'utils/paths';
import { setToken } from 'actions/auth';
import setAuthToken from 'utils/setAuthToken';
import { fetchSession } from 'actions/session';

const ROLE_GUEST = 'guest';
const ROLE_USER = 'user';

export const authRoles = {
  guest: ROLE_GUEST,
  user: ROLE_USER,
};

const getRedirectPath = (pathname) => {
  return pathname === rootAppPath || pathname === loginAppPath
    ? loginAppPath
    : pathname === '/recovery' ||
      pathname === recoveryPath ||
      pathname === setPasswordPath ||
      pathname === refreshEmailPath ||
      pathname === errorPath
    ? pathname
    : `${loginAppPath}?${pathname}`;
};

const ProtectedRoutes = (props) => {
  const { auth, session, fetchSession, children } = props;
  let token = cookie.load('token');
  const location = useLocation();
  const { search, pathname } = location;
  const { fetchingOne, fetchedOne } = session;

  // IF SEARCH -> SET TOKEN
  if (search) {
    const searchParams = new URLSearchParams(search);
    token = searchParams.get('token') || token;
    if (token) {
      setToken(token);
    }
  }

  if (auth.isAuthorized) {
    // Disable auth
    return <>{children}</>;
  }

  if (!fetchedOne && !fetchingOne && token) {
    setAuthToken(token);
    const refreshSession = async () => await fetchSession();
    const res = refreshSession();
    res.then(() => {
      return <>{children}</>;
    });
    // return false;
  }

  if (!fetchingOne && (fetchedOne || !token)) {
    const redirectUrl = getRedirectPath(pathname);
    return <Navigate to={redirectUrl} state={{ from: location }} replace />;
  }

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <>{children}</>;
};

const mapDispatchToProps = {
  fetchSession,
};

const mapStateToProps = ({ session, auth }) => ({
  session,
  auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutes);
