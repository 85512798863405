import tmp_logo from 'assets/images/tmp-logo.png';
import xceedanceHeroImage from 'assets/images/xceedance_hero.png';
import heroImage from 'assets/images/hero-image2.png';
import Logo from 'components/Logo';
import { isAdmiral, isHorwich, isThompsons, isXceedance } from 'utils/helpers';

export const displayLogo = ({ companyLogo = '' }) => {
  if (isXceedance()) return null;

  if (isThompsons()) {
    return (
      <>
        <Logo
          src={tmp_logo}
          className="margin-30-bottom"
          width={300}
          height={70}
          fullLogo
        />
        <p className="tmp-welcome-text">Welcome to the Thompsons Client Hub</p>
      </>
    );
  }

  return (
    <Logo
      src={companyLogo}
      className="margin-30-bottom max-width-60"
      width={175}
      height={20}
      fullLogo
    />
  );
};

export const setLoginStyles = ({ window }) => {
  if (isThompsons()) return 'tmp-login';
  else if ((isHorwich() || isAdmiral()) && window?.innerHeight <= 800)
    return 'horwich-login-left';
  else if (isXceedance()) return 'xceedance-login';
  else return 'login-left';
};

export const setHeroImage = () => {
  if (isXceedance()) return xceedanceHeroImage;
  return heroImage;
};
