const initState = {
  data: {},
  list: [],
  errors: [],
  hasErrors: false,
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  fetchingOne: false,
  fetchedOne: false,
  deleting: false,
  deleted: false,
};

export default initState;
