import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchSettings } from "actions/settings";

class Settings extends Component {
  componentDidMount() {
    const { fetchedOne } = this.props.settings;

    if (!fetchedOne) this.props.fetchSettings();
  }

  render() {
    const { fetchedOne } = this.props.settings;

    return fetchedOne ? this.props.children : <div className="lds-dual-ring" />;
  }
}

Settings.propTypes = {
  fetchSettings: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    fetchedOne: PropTypes.bool
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

const mapDispatchToProps = {
  fetchSettings
};

const mapStateToProps = state => ({
  settings: state.settings
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
