/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  SET_ACCOUNT_FIELD,
  SET_ACCOUNT_FIELDS,
  CLEAR_ACCOUNT_STATE,
} from 'actions/types';

const initialState = {
  errorText: '',
  fields: {},
  changedFields: {},
  fieldsUpdated: false,
};

const accountRestTypes = restTypes('account');

const { fetchOneStart, fetchOneFinished, createStart, createFinished } =
  accountRestTypes;

const account = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        errorText: action.payload.errorText,
      };

    case SET_ACCOUNT_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        changedFields: {
          ...state.changedFields,
          [action.payload.name]: action.payload.value,
        },
      };

    case SET_ACCOUNT_FIELDS:
      return {
        ...state,
        fields: action.payload,
        fieldsUpdated: true,
      };

    case CLEAR_ACCOUNT_STATE:
      return {
        ...state,
        fields: {},
        changedFields: {},
      };

    default:
      return state;
  }
};

export default account;
