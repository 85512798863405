const config = {
  env: process.env.REACT_APP_APP_ENV,
  apiPath: process.env.REACT_APP_API_PATH,
  apiPathSecond: process.env.REACT_APP_API_PATH_SECOND,
  apiCMSPath: `${process.env.REACT_APP_API_PATH}/cms`,
  token: process.env.REACT_APP_TOKEN,
  clickToAddressToken: process.env.REACT_APP_CLICK_TO_ADDRESS_TOKEN,
  helpHeroId: process.env.REACT_APP_HELP_HERO_INIT,
  intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID,
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  googleMapsKeyNew: process.env.REACT_APP_GOOGLE_MAPS_KEY_NEW,
  sgPuzzleKey: process.env.REACT_APP_SG_PUZZLE_KEY,
};
export default config;
