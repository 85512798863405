import axios from "axios";
import { camelizeKeys } from "humps";
import cookie from "react-cookies";

/* UTILS */
import setAuthToken from "utils/setAuthToken";

/* PATH */
import { recoveryPath, refreshEmailApiPAth } from "utils/paths";

/* TYPES */
import {
  SET_IS_AUTHORIZED,
  SEND_RECOVERY_EMAIL_START,
  SEND_RECOVERY_EMAIL_FINISH
} from "actions/types";

/* ACTIONS */
export const setIsAuthorized = payload => ({
  type: SET_IS_AUTHORIZED,
  payload
});

export const setToken = token => {
  cookie.save("token", token, { path: "/" });
  setAuthToken(token);
};

export const clearToken = () => {
  cookie.remove("token", { path: "/" });
};

export const sendRecoveryEmail = email => dispatch => {
  dispatch({
    type: SEND_RECOVERY_EMAIL_START
  });

  return axios.post(recoveryPath, email).then(
    ({ data }) => {
      dispatch({
        type: SEND_RECOVERY_EMAIL_FINISH,
        payload: camelizeKeys(data)
      });
    },
    error => {
      dispatch({
        type: SEND_RECOVERY_EMAIL_FINISH,
        payload: {
          hasErrors: true,
          errorText: error.response.data.error
        }
      });
    }
  );
};

export const refreshEmail = (t, cb) => {
  return axios
    .post(refreshEmailApiPAth, { t })
    .then(res => {
      cb(null, res);
    })
    .catch(({ response }) => {
      cb(response.data);
    });
};
