/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

const initialState = {};
const userRestTypes = restTypes('user');

const { fetchOneStart, fetchOneFinished, updateStart, updateFinished } =
  userRestTypes;

const user = (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
        hasErrors: false,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default user;
