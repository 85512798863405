/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  SET_TASK_CLAIM_GROUPS,
  SET_FORM_CLAIM_ANSWER,
  CLEAR_TASK_CLAIM_ANSWERS,
} from 'actions/types';

const initialState = { errorText: '', groups: {}, answers: [] };

const taskClaimRestTypes = restTypes('taskClaim');

const { fetchOneStart, fetchOneFinished, updateStart, updateFinished } =
  taskClaimRestTypes;

const taskClaim = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        data: action.payload,
      };

    case SET_TASK_CLAIM_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };

    case SET_FORM_CLAIM_ANSWER:
      return {
        ...state,
        answers:
          state.answers.length > 0 &&
          state.answers.find(
            (answ) => answ.questionId === action.payload.questionId
          )
            ? state.answers.map((answ) => {
                if (answ.questionId === action.payload.questionId) {
                  return { ...answ, value: action.payload.value };
                }
                return answ;
              })
            : [...state.answers, action.payload],
      };

    case CLEAR_TASK_CLAIM_ANSWERS:
      return {
        ...state,
        answers: [],
      };

    default:
      return state;
  }
};

export default taskClaim;
