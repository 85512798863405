import { combineReducers } from "redux";

import browser from "./browser";
import task from "./task";
import customer from "./customer";
import claim from "./claim";
import session from "./session";
import auth from "./auth";
import user from "./user";
import account from "./account";
import customerClaim from "./customerClaim";
import customerDocument from "./customerDocument";
import claimTask from "./claimTask";
import claimDocument from "./claimDocument";
import claimCorrespondence from "./claimCorrespondence";
import header from "./header";
import taskClaimDocument from "./taskClaimDocument";
import autocomplete from "./autocomplete";
import taskClaim from "./taskClaim";
import document from "./document";
import settings from "./settings";
import people from "./people";
import organization from "./organization";
import contacts from "./contacts";
import staticTexts from "./staticTexts";

export default combineReducers({
  browser,
  task,
  customer,
  claim,
  session,
  auth,
  user,
  account,
  customerClaim,
  customerDocument,
  claimTask,
  claimDocument,
  header,
  taskClaimDocument,
  autocomplete,
  taskClaim,
  document,
  settings,
  people,
  organization,
  contacts,
  claimCorrespondence,
  staticTexts
});
