import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';

/* PAGES */
// import AuthRouter from 'routes/AuthRouter';
// import Login from 'pages/Login';
// import SetPassword from 'pages/SetPassword';
// import RefreshEmail from 'pages/RefreshEmail';
// import NotFound from 'pages/NotFound';
// import TrustySuccess from 'pages/TrustySuccess';

/* PATH */
import {
  loginAppPath,
  setPasswordPath,
  refreshEmailPath,
  errorPath,
  trustySuccessPath,
} from 'utils/paths';
// import Ring from 'components/Ring';
import ErrorBoundary from '../components/Layout/ErrorBoundary';
import ProtectedRoutes from '../components/Auth/ProtectedRoutes';
import Ring from 'components/Ring';

const AuthRouter = lazy(() => import('routes/AuthRouter'));
const Login = lazy(() => import('pages/Login'));
const SetPassword = lazy(() => import('pages/SetPassword'));
const RefreshEmail = lazy(() => import('pages/RefreshEmail'));
const NotFound = lazy(() => import('pages/NotFound'));
const TrustySuccess = lazy(() => import('pages/TrustySuccess'));

function MainRouter() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Ring />}>
        <Routes>
          <Route path={`${loginAppPath}/*`} element={<Login />} />
          <Route path={setPasswordPath} element={<SetPassword />} />
          <Route path={refreshEmailPath} element={<RefreshEmail />} />
          <Route path={errorPath} element={<NotFound />} />
          <Route path={trustySuccessPath} element={<TrustySuccess />} />

          <Route
            path="/*"
            element={
              <ProtectedRoutes>
                <AuthRouter />
              </ProtectedRoutes>
            }
          ></Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
// }

const mapStateToProps = ({ session, claim, auth }) => ({
  session,
  claim,
  auth,
});

export default connect(mapStateToProps)(MainRouter);
