/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import { CLEAR_DOCUMENT_LIST } from 'actions/types';

const initialState = {
  list: [],
};

const taskClaimDocumentRestTypes = restTypes('taskClaimDocument');

const { fetchAllStart, fetchAllFinished, createStart, createFinished } =
  taskClaimDocumentRestTypes;

const taskClaimDocument = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };

    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload.result,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
      };

    case CLEAR_DOCUMENT_LIST:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default taskClaimDocument;
