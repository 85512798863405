/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import { CLEAR_DOCUMENT_LIST } from 'actions/types';

const initialState = {
  list: [],
};
const customerDocumentRestTypes = restTypes('customerDocument');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchOneStart,
  fetchOneFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
} = customerDocumentRestTypes;

const customerDocument = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };

    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload.result,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };

    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
        ...action.payload,
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
      };

    case CLEAR_DOCUMENT_LIST:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default customerDocument;
