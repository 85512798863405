import _ from 'lodash';
/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  SET_CLAIM_GROUPS,
  SET_CLAIM_ANSWER,
  FETCH_CLAIM_TYPES_FINISH,
  CLEAR_CLAIM_ANSWERS,
  CLEAR_CLAIM_DATA,
  CLEAR_CLAIM_GROUP,
  FETCH_LINKED_CLAIMS_START,
  FETCH_LINKED_CLAIMS_FINISHED,
  FETCH_LINKED_CLAIMS_SEARCH_START,
  FETCH_LINKED_CLAIMS_SEARCH_FINISHED,
} from 'actions/types';

const initialState = {
  groups: {},
  answers: [],
  linkedClaimsList: [],
  fetchLinkedClaimsStart: false,
  fetchLinkedClaimsFinished: false,
  fetchLinkedClaimsSearchStart: false,
  fetchLinkedClaimsSearchFinished: false,
};
const claimRestTypes = restTypes('claim');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchOneStart,
  fetchOneFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
} = claimRestTypes;

const claim = (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };

    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload.result,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
      };

    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        data: action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        hasErrors: _.get(action.payload, 'hasErrors', false),
        errors: _.get(action.payload, 'errors', []),
        // errors:  action.payload.errors
      };

    case SET_CLAIM_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };

    case SET_CLAIM_ANSWER:
      return {
        ...state,
        answers:
          state.answers.length > 0 &&
          state.answers.find(
            (answ) => answ.questionId === action.payload.questionId
          )
            ? state.answers.map((answ) => {
                if (answ.questionId === action.payload.questionId) {
                  return { ...answ, value: action.payload.value };
                }
                return answ;
              })
            : [...state.answers, action.payload],
      };

    case FETCH_CLAIM_TYPES_FINISH:
      return {
        ...state,
        types: action.payload.result,
      };

    case CLEAR_CLAIM_ANSWERS:
      return {
        ...state,
        answers: [],
      };

    case CLEAR_CLAIM_DATA:
      return {
        ...state,
        data: {},
      };

    case CLEAR_CLAIM_GROUP:
      return {
        ...state,
        groups: {},
      };

    case FETCH_LINKED_CLAIMS_START:
      return {
        ...state,
        fetchLinkedClaimsStart: true,
      };

    case FETCH_LINKED_CLAIMS_FINISHED:
      return {
        ...state,
        fetchLinkedClaimsStart: false,
        fetchLinkedClaimsFinished: true,
        linkedClaimsList: action.payload.result,
      };

    case FETCH_LINKED_CLAIMS_SEARCH_START:
      return {
        ...state,
        fetchLinkedClaimsSearchStart: true,
      };

    case FETCH_LINKED_CLAIMS_SEARCH_FINISHED:
      return {
        ...state,
        fetchLinkedClaimsSearchStart: false,
        fetchLinkedClaimsSearchFinished: true,
        list: action.payload.result,
      };

    default:
      return state;
  }
};

export default claim;
