/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import { SET_NEW_PASSWORD, SET_REDIRECT_URL } from 'actions/types';

const initialState = { errorText: '' };

const sessionRestTypes = restTypes('session');
const { fetchOneStart, fetchOneFinished, createStart, createFinished } =
  sessionRestTypes;

const session = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };
    case createStart:
      return {
        ...state,
        ...restStates.createStart,
        hasErrors: false,
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        errorText:
          action.payload && action.payload.errorText
            ? action.payload.errorText
            : null,
        hasErrors:
          action.payload && action.payload.hasErrors
            ? action.payload.hasErrors
            : null,
      };

    case SET_NEW_PASSWORD:
      return {
        ...state,
        newPasswordSaved: action.payload,
        errorText: action.error,
      };

    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload,
      };

    default:
      return state;
  }
};

export default session;
