import axios from 'axios';
import humps from 'lodash-humps';

/* UTILS */
import RestActions from 'utils/rest/actions';

/* PATH */
import { settingsPath } from 'utils/paths';

import { isThompsons } from 'utils/helpers';

const restSettingsActions = new RestActions('settings');
const { fetchStart, fetchFinished } = restSettingsActions;

/* TODO: MOVE TO UTILS */

const arrayToObject = (array) =>
  array instanceof Array
    ? array.reduce((obj, item) => {
        obj[item.name] = item.value; // eslint-disable-line
        return obj;
      }, {})
    : { result: array };

const parseSettings = ({ success, result }) => ({
  ...humps(arrayToObject(result)),
  success,
});

const handleFavicon = (faviconUrl) => {
  const favicon = document.getElementById('favicon');
  favicon.href = faviconUrl;
};

/* ACTIONS */
export const fetchSettings = () => (dispatch) => {
  dispatch(fetchStart());
  return axios.get(settingsPath).then(
    ({ data }) => {
      const res = parseSettings(data);
      dispatch(fetchFinished(res));

      if (isThompsons()) document.title = 'Thompsons Client Hub';
      else document.title = res.companyName;

      if (res.faviconLink) {
        handleFavicon(res.faviconLink);
      }

      const html = document.getElementsByTagName('html')[0];
      html.style.setProperty('--main-color', res.primaryColor);
    },
    (error) => {
      dispatch(fetchFinished(error));
    }
  );
};
