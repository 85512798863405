/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';
import {
  CLEAR_CONTACT_SUGGESTIONS,
  FETCH_ROLES,
  SET_SUGGESTION,
} from '../actions/types';

/* TYPES */

const initialState = {
  list: [],
  fields: {},
  roles: [],
  suggestions: [],
  changedFields: {},
  fieldsUpdated: false,
  editMode: false,
  claims: {
    list: [],
  },
  documents: [],
  lastUploadedDocument: {},
  hasErrors: false,
  errors: {},
};

const contactsRestTypes = restTypes('contacts');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchOneStart,
  fetchOneFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
} = contactsRestTypes;

const contacts = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };

    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload.contacts,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };

    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        data: action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
        hasErrors: false,
        errors: {},
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        hasErrors: action.payload.hasErrors,
        errors: action.payload.errors,
      };

    case FETCH_ROLES:
      return {
        ...state,
        ...restStates.createFinished,
        roles: action.payload.roles,
      };

    case SET_SUGGESTION:
      return {
        ...state,
        suggestions: action.payload.contactables,
      };

    case CLEAR_CONTACT_SUGGESTIONS:
      return {
        ...state,
        suggestions: [],
        list: [],
      };

    default:
      return state;
  }
};

export default contacts;
