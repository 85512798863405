import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { rootAppPath } from 'utils/paths';
import { withRouter } from 'hooks/withRouter';

import Button from '../Button';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      title: '',
      details: '',
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      title: error.message,
      details: error.stack,
    };
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="error-container flex justify-center direction-column">
          <div className="error-content flex justify-center direction-column">
            <h3>Something went wrong.</h3>
            {/* <h4>{title}</h4> */}
            {/* <p>{details}</p> */}
            <p>Please, try again</p>
            <Button
              type="success"
              value="Take me back to homepage"
              pathTo={rootAppPath}
              className="col-xs-4 padding-20-left padding-20-right"
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ErrorBoundary.defaultProps = {
  children: [],
};

export default withRouter(connect(null, {})(ErrorBoundary));
