/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  SET_ORGANIZATION_FIELD,
  SET_ORGANIZATION_FIELDS,
  SET_ORGANIZATION_EDIT_MODE,
  CLEAR_ORGANIZATION_DATA,
} from 'actions/types';

const initialState = {
  list: [],
  fields: {},
  changedFields: {},
  fieldsUpdated: false,
  editMode: false,
  claims: [],
  documents: [],
  lastUploadedDocument: {},
  hasErrors: false,
  errors: {},
};

const OrganizationRestTypes = restTypes('organization');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchOneStart,
  fetchOneFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
  fetchOrganisationClaimsStart,
  fetchOrganisationClaimsFinished,
} = OrganizationRestTypes;

const organisation = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };

    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload.organizations,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };

    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        data: action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
        hasErrors: false,
        errors: {},
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        hasErrors: action.payload.hasErrors,
        errors: action.payload.errors,
      };

    case fetchOrganisationClaimsStart:
      return {
        ...state,
        ...restStates.fetchOrganisationClaimsStart,
      };

    case fetchOrganisationClaimsFinished:
      return {
        ...state,
        ...restStates.fetchOrganisationClaimsFinished,
        claims: action.payload.result,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case SET_ORGANIZATION_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        changedFields: {
          ...state.changedFields,
          [action.payload.name]: action.payload.value,
        },
      };

    case SET_ORGANIZATION_FIELDS:
      return {
        ...state,
        fields: action.payload,
        fieldsUpdated: true,
      };

    case SET_ORGANIZATION_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload,
      };

    case CLEAR_ORGANIZATION_DATA:
      return {
        ...state,
        data: {},
        fields: {},
        claims: [],
      };

    default:
      return state;
  }
};

export default organisation;
