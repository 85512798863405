/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

const initialState = { errorText: '' };

const staticTextsRestTypes = restTypes('staticTexts');

const { fetchOneStart, fetchOneFinished } = staticTextsRestTypes;

const staticTexts = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default staticTexts;
